

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@tailwind base;
@tailwind components;
@tailwind utilities;

//vue-flash-message
@import '~vue-flash-message/dist/vue-flash-message.min.css';
//Phrase Tables
@import 'phrase-tables';

@import 'search-box';
@import 'edit-phrase';

[x-cloak] {
    display: none !important;
}

.navbar-laravel {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.count {
    color: $gray-400;
}

.card {
  margin-bottom: 2rem;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
   background-color: $gray-100; // Choose your own color here
 }

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.icons{
  padding: 7px -1px;
  .indicator{
    border-radius: 9px;
    width: 9px;
    height: 9px;
    margin: 1px;
  }
  .meaning{
    background-color: $blue;
  }
  .keywords{
    background-color: lighten($teal,9%);
  }
}

.card.mini-phrase {
    margin: 0.5rem;
    transition: transform 0.5s;
    &:hover{
      transform: scale(1.02);
      box-shadow: $gray-300 0 0 15px;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        padding-bottom: 0.2rem;
        color: $gray-700;


        .keywords{
            flex-grow: 1;
        }

        .meta {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            justify-content: space-between;

            .trash {
                position: absolute;
                top: 5px;
                right: 5px;
                opacity: 0;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
