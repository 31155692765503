.table-list{
  white-space: nowrap;
  table-layout: fixed;
  td a{
    color: #696969;
    text-decoration: none;
    display: block;
    pointer: hand;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
