#custom-search-input {
    padding: 3px;
    border: solid 1px #E4E4E4;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: #efefef 0 3px 15px;
    z-index: 1;

}
@media (prefers-color-scheme: dark){
    #custom-search-input {
        background-color: #222;
        box-shadow: #010101 0 3px 15px;
    }
    }
#custom-search-input input {
    border: 0;
    box-shadow: none;
}

#custom-search-input button {
    margin: 2px 0 0;
    background: none;
    box-shadow: none;
    border: 0;
    color: #666666;
    padding: 0 8px 0 10px;
    border-left: solid 1px #ccc;
    border-radius: 0;
}

#custom-search-input button:hover {
    border: 0;
    box-shadow: none;
    border-left: solid 1px #ccc;
}

#custom-search-input .fas {
    font-size: 23px;
    line-height: 34px;
    color: $gray-500;
}

.search-box-element {
    margin-bottom: 1.5rem;
}

.search-results-popup{

    box-shadow: #efefef 0 5px 15px;
    max-height: 80vh;
    overflow: hidden;
}
.search-results {
    background-color: white;
    text-align: left;
    .btn-primary{
      color: #fff;
    }
    .column-phrase{
      width: 90%;
    }

    .column-trash{
      width: 45px;
      a{
      color: transparent;
        &:hover{
          color: $red;
        }
      }
    }

}
