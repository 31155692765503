
.container{
    width: 90%;
    margin: 0 auto;
}

.phrase-edit{
    width: 100%;
    border: none;
    font-family: "fira sans";
}
.phrase-edit label{
    font-weight: 200;
}

.animated {
    -webkit-transition: height 0.2s;
    -moz-transition: height 0.2s;
    transition: height 0.2s;
}

.phrase-edit textarea
{
    border: none;
    width: 100%;
    background-color: $body-bg;
    resize: none;
    min-height: 3rem;
}

.phrase-edit textarea:focus
{
    outline: none;
}

.phrase-edit #phrase{
    font-size: 2rem;
    font-weight: 800;
    border-bottom: 1px solid #ddd;
}

.phrase-edit #keywords,
.phrase-edit #meaning{
    font-size: 1.4rem;
}

a .delete {
    margin-top: 20px;
    margin-bottom: 10px;
}
